.back {
    background-color: black;
    margin-top: 1vh;
    margin-bottom: 1vh;
    padding-top: 2vh;
    padding-bottom: 2vh;
    height: 94vh;
    width: 100vw;
    box-sizing: initial;
}

.list {
    color: white;
    border: solid 1px white;
    border-radius: 5px;
    margin-left: 3vw;
    margin-right: 3vw;
    font-family: 'misaki';
}

.item,.item-disable{
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.item::before {
    content: "▶︎ ";
}

.item-disable::before {
    content: none;
}

.msg-window {
    color: white;
    border: solid 1px white;
    border-radius: 5px;
    margin-left: 3vw;
    margin-right: 3vw;
    margin-top: 2vh;
    padding-top: 3vh;
    padding-bottom: 3vh;
    font-family: 'misaki';
}

@font-face {
    font-family: 'misaki';
    src: url('https://cdn.leafscape.be/misaki/misaki_gothic_web.woff2') format("woff2");
}
